"use client";

import { motion, Variants } from "framer-motion";
import React from "react";

type Props = {
  children: React.ReactNode;
};

const sectionVariant: Variants = {
  offscreen: {
    x: -1500,
    opacity: 0,
    // rotate: -50
  },
  onscreen: {
    x: 0,
    opacity: 1,
    // rotate: 0,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

const AnimationSection = (props: Props) => {
  return (
    <motion.div
      variants={sectionVariant}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
    >
      {props.children}
    </motion.div>
  );
};

export default AnimationSection;
