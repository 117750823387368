import { ABOUT_PATH, HOME_PATH, TESTIMONIALS_PATH } from "./url"

export const COMPANY_NAME = "Fuzen"

export const WEBSITE_TITLE = `${COMPANY_NAME}: Where Web Innovation Meets Excellence`
export const WEBSITE_DESC = 'Turning Clicks into Impactful Web Experiences'

export const NAV_ITEMS = [
    {
        title: "Home",
        path: HOME_PATH
    },
    {
        title: "About",
        path: ABOUT_PATH
    },
    {
        title: "Testimonials",
        path: TESTIMONIALS_PATH
    }
]